import React from "react"
import { graphql, Link } from "gatsby"

import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import FeatureSide from '../../../components/feature-side'
import FeatureRelated from '../../../components/feature-related'

const Home = ({ data }) => {
  return (
    <Layout>
      <SEO
        pagetitle="落ち込んでしまったあなたへ。前向きになれる力を持つ名言！"
        pagepath="/feature/04/"
      />
      <main>
        <div className="l-feature__mv">
          <div className="l-feature__bg">
            <StaticImage
							src="../../../assets/images/feature/ochikomu01.jpg"
							alt="落ち込んでしまったあなたへ。前向きになれる力を持つ名言！"
						/>
          </div>
          <div className="l-feature__above">
            <h3 className="l-feature__hdg">落ち込んでしまったあなたへ。前向きになれる力を持つ名言！</h3>
          </div>
        </div>
        <div className="l-feature">
          <div className="l-wrap">
            <div className="l-feature__item">
              <div className="l-feature__box">

                <div className="l-feature__main">

                  <div className="l-feature__trunk">
                    <div className="l-feature__paragraph">
                      <h2 className="l-feature__lead">
                        <span>落ち込んでしまったあなた。</span>
                        <span>人間関係・仕事・家族・友人やペットの死など、生きていると落ち込むことはあります。</span>
                        <span>
                          そんなあなたへ、偉人たちの言葉を。<br />
                          立ち直る勇気をくれる名言をご紹介します！
                        </span>
                      </h2>
                    </div>

                    <div className="l-feature__paragraph">
                      <div className="l-feature__wrap">
                        <div className="l-feature__num">01.</div>
                        <div className="l-feature__quote">
                          {data.post1.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>古代ローマの政治家、思想家。 雄弁家であったキケロ。</p>
                        <p>同じような名言としては、小説家の瀬戸内寂聴の言葉</p>
                        <p><span className="f-l">「時間こそが心の傷の妙薬なのです。」</span></p>
                        <p>があります。</p>
                        <p>知恵者は、時間の力と悲しみの本質を知りこのような言葉を残してくれたんですね。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">02.</div>
                        <div className="l-feature__quote">
                          {data.post2.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>ニーチェの有名な言葉。</p>
                        <p><span className="marker-b">後悔は決して悪いものではありません。</span></p>
                        <p>後悔から学び、同じ轍を踏まない限り、<span className="f-l">後悔は経験すべきもの</span>だったと言えるからです。</p>
                        <p>しかし愚かなわたしたちは、<span className="marker-b">くよくよと後悔をこねくり回し貴重な時間を無駄に過ごして</span>しまいます。</p>
                        <p>その愚かな行動は、現代においても、ニーチェの生きた時代においても同じだったのでしょう。</p>
                        <p>この言葉は、そのような私たちの弱さを喝破してくれる力を持っています。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">03.</div>
                        <div className="l-feature__quote">
                          {data.post3.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>困難を乗り越えた音楽家、ベートーベン。</p>
                        <p>音楽家として、致命的であろう<span className="marker-y">聴覚障害という困難</span>に遭いながらも、優れた作品を世に作り出してきました。</p>
                        <p>そんな彼の言葉だからこそ、意味と重みが痛切に感じられます。</p>
                        <p>ベートーベンのように大変な困難を乗り越えた存在を知ることで、わたしたちも強さをもらえます。</p>
                        <p>困難にぶち当たった時は、<span className="marker-b">その先にある栄光をイメージしてみる</span>のはいかがでしょうか。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">04.</div>
                        <div className="l-feature__quote">
                          {data.post4.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>ニーチェは、晩年、<span className="f-l">発狂</span>してしまいます。</p>
                        <p>精神病院に通いますが、晩年には医師の疎通が困難になります。</p>
                        <p>そんなニーチェ自身の未来を占うかのように、この名言を残しました。</p>
                        <p><span className="f-l">この世界は、苦に満ちています。</span></p>
                        <p>仏教の開祖、お釈迦様がおっしゃった言葉でもあります。</p>
                        <p>私たちも生きる中で、死にたくなるような困難にぶち当たることがあります。</p>
                        <p>それは人間としてこの世界に生まれてきた宿命です。避けることができません。</p>
                        <p>私たちが取れる選択肢は2つだけです。<span className="marker-b">その困難から逃げるか、乗り越えるか。</span></p>
                        <p>ずっと強くなるために、輝かしい未来を手に入れるために、私たちは乗り越えたいものですね。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">05.</div>
                        <div className="l-feature__quote">
                          {data.post5.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>倒れずに成功を勝ち取る人間は誰もいません。</p>
                        <p><span className="marker-b">エジソンにはお金にもならなかった、特許が山のように</span>あります。</p>
                        <p><span className="marker-b">アルバートアインシュタインには、誰にも引用されなかった論文が山ほど</span>あります。</p>
                        <p><span className="marker-b">本になっていない文章が村上春樹にはたくさんある</span>ことでしょう。</p>
                        <p>失敗は私たちの人生のデフォルトです。</p>
                        <p>だから失敗にくよくよするのは愚かです。</p>
                        <p>ポイントは、<span className="f-l marker-b">失敗から学び、失敗からすぐに立ち上がる</span>ことです。</p>
                        <p>ゴールドスミスが言いたかったこともそうではないでしょうか、そして彼の人生がそのことを証明しています。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">06.</div>
                        <div className="l-feature__quote">
                          {data.post6.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>私たち人間は<span className="marker-y">過去の失敗をくよくよする性質</span>があります。</p>
                        <p>しかしそのような性質によって、生物として長く生きながらえることができた背景もあるでしょう。</p>
                        <p>しかし私たちがやるべきことは、<span className="marker-y">過去に生きることではなく今を全力で生きること</span>です。</p>
                        <p>そのためには<span className="f-l">元気</span>が必要です。元気がないと今に集中することができません。未来を楽しむことができません。</p>
                        <p><span className="f-l">楽しいから笑うのか、それとも笑うから楽しいのか</span></p>
                        <p>有名な哲学的命題ですが、それはどちらも正しいと思います。</p>
                        <p><span className="marker-b">元気を出しましょう。笑いましょう。</span>過去のことを考えるのではなく<span className="marker-b">明日訪れるかもしれない成功</span>について考えましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">07.</div>
                        <div className="l-feature__quote">
                          {data.post7.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>バルザック節炸裂ですね。</p>
                        <p>言われてみると、確かに私たちは大げさに、不幸と幸福を考えすぎているのかもしれません。</p>
                        <p>その結果、幸福のステレオタイプ、不幸のステレオタイプに囚われ、身動きが取れなくなっているのかもしれませんね。</p>
                        <p>バルザックが言いたかったことは、</p>
                        <p><span className="f-l">気楽に生きよう！</span>ということではないでしょうか？</p>
                        <p><span className="marker-y">どんなことがあっても大したことがない</span>と思うだけで、気楽に対処できますよね！</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">08.</div>
                        <div className="l-feature__quote">
                          {data.post8.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>ボクシングの輪島幸一さんのカエルパンチを思い出しました。</p>
                        <p>なにごとにも、停滞期というものがあります。</p>
                        <p>そんな時、私たちは意気消沈し、やる気を失い、くよくよしてしまいます。</p>
                        <p>しかし、考えてみますと<span className="marker-y">順風満帆のまま物事が終わる</span>ことはありません。</p>
                        <p>仏教では『無常』という概念でそれを見事に説明していますが、そんなことを持ち出すまでもなく毎日の生活の中でそれを経験しています。</p>
                        <p>だからこそ、どん底を<span className="f-l">織り込み済み</span>で考えましょう。</p>
                        <p>予定通りのイベントが、予定通りに発生しただけと思えば、あとは<span className="marker-y">立ち上がるのみ。</span></p>
                        <p>カエルパンチのように、力を蓄えるんだ！　と思えばそのプロセスも前向きにこなせますよね。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">09.</div>
                        <div className="l-feature__quote">
                          {data.post9.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>仏教の開祖お釈迦様が最初に音気になった教えは、四聖諦と呼ばれます。</p>
                        <p>四聖諦は、4つの諦（集まり）から成ります。</p>
                        <ul>
                          <li>苦諦</li>
                          <li>集諦</li>
                          <li>滅諦</li>
                          <li>道諦</li>
                        </ul>
                        <p>「苦諦」とは、人生は苦ですよという現状分析のこと。</p>
                        <p>「集諦」とは、煩悩やエゴにより苦が発生しますよという説明のこと。</p>
                        <p>「滅諦」とは、煩悩やエゴを滅すれば、苦はなくなりますという説明のこと。</p>
                        <p>「道諦」とは、そのための方法は、八正道ですと、という結論のことです。</p>
                        <p>仏教の実践者は、この真実をベースにさまざまな修行を展開します。</p>
                        <p><span className="marker-y">ニーチェも、お釈迦様と同じように生きることはすなわち苦であることを看破しました。</span></p>
                        <p><span className="marker-b">苦しみはマストなのだから、その中から意味あることを見つけ、実践することが出来る人が智者だ</span>と言いたかったのだと推測します。</p>
                        <p>もしあなたが苦しみの最中にあるならば、その中から少しでも自分の成長につながる事はないかという目線を持ちましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">10.</div>
                        <div className="l-feature__quote">
                          {data.post10.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>グレートギャツビーなどの作品で知られる小説家フィッツジェラルド。</p>
                        <p>実践的な言葉ですね。</p>
                        <p>私たちが生きる失敗がデフォルトです。成功することの方が稀でしょう。</p>
                        <p>そんな事は理解しつつも、<span className="marker-y">ただ一回の失敗や敗北を大げさに捉えてしまいがち</span>です。</p>
                        <p>そんな時は、フィッツジェラルドの言葉を思い出しましょう。</p>
                        <p>私たちが知っている偉人は、<span className="marker-y">失敗しなかったわけではありません。</span></p>
                        <p>むしろ普通の人よりも<span className="f-l">たくさん失敗をしている</span>でしょう。</p>
                        <p>偉人が偉人たる所以は、<span className="marker-b">その失敗から立ちなおり、何度も何度もチャレンジしたこと</span>です。</p>
                        <p><span className="f-l">たった1回の失敗なんてくそくらえ!</span> この熱意で乗り越えましょう!</p>
                      </div>

                    </div>
                  </div>
                  <div className="l-feature__trunk">
                    <FeatureRelated />
                  </div>
                </div>

                <div className="l-feature__data">
                  <FeatureSide data={data} />
                </div>

              </div>

            </div>

          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    post1: allContentfulBlogPost(
      filter: {id: {eq: "aeb2730b-77b8-5ce0-8267-7087f7b73a76"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post2: allContentfulBlogPost(
      filter: {id: {eq: "7de8453b-2b68-525d-b58f-4b9d0b4dcac3"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post3: allContentfulBlogPost(
      filter: {id: {eq: "2642143f-ebfe-5d17-9a09-95bc062b2dca"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post4: allContentfulBlogPost(
      filter: {id: {eq: "51f7755b-8499-598c-8861-90ad032d1fd0"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post5: allContentfulBlogPost(
      filter: {id: {eq: "200c408b-36b2-5568-9438-2e2b4ce5bde1"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post6: allContentfulBlogPost(
      filter: {id: {eq: "47f4c717-5be1-575d-80d1-c97e3e6be01e"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post7: allContentfulBlogPost(
      filter: {id: {eq: "7a5ed837-b64c-54a7-bdbe-f5cbb4ee660a"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post8: allContentfulBlogPost(
      filter: {id: {eq: "ae75c415-ac1c-5aac-8871-9d0cad954531"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post9: allContentfulBlogPost(
      filter: {id: {eq: "04c45f44-5dc1-5262-ac8f-7fc522d279ac"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post10: allContentfulBlogPost(
      filter: {id: {eq: "038f9f78-4dc0-591b-aae5-e9165c3fa28f"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    cate: allContentfulCategory {
      edges {
        node {
          informations {
            informations
          }
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    allCate: allContentfulCategory(
      sort: {fields: sort, order: DESC},
      limit: 20
    ) {
      edges {
        node {
          name
          slug
          id
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    tags: allContentfulTags {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
  }
`

export default Home