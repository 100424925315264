import React from 'react';
import { Link } from "gatsby"

import { StaticImage } from 'gatsby-plugin-image'

const FeatureRelated = ({ data }) => {
  return (
    <>
      <div className="l-feature__related">
        <h4 className="m-heading">こちらのブログも人気です</h4>
        <ul className="l-feature__recommend recommend">
          <li className="recommend__item">
            <Link to="/feature/01/">
              <div className="recommend__img">
                <StaticImage
                  src="../../../assets/images/feature/gandhi01.jpg"
                  alt="ガンジー"
                />
              </div>
              <div className="recommend__text">
                インド独立の父、マハトマ・ガンジーの言葉から学ぼう！
              </div>
            </Link>
          </li>

          <li className="recommend__item">
            <Link to="/feature/02/">
              <div className="recommend__img">
                <StaticImage
                  src="../../../assets/images/feature/artist01.jpg"
                  alt="夢に突き進むあなたへ。勇気をもらえる名言"
                />
              </div>
              <div className="recommend__text">
                夢に突き進むあなたへ。勇気をもらえる名言
              </div>
            </Link>
          </li>

          <li className="recommend__item">
            <Link to="/feature/03/">
              <div className="recommend__img">
                <StaticImage
                  src="../../../assets/images/feature/retire01.jpg"
                  alt="定年退職を迎えたあなたへ！これからの人生を楽しむための名言"
                />
              </div>
              <div className="recommend__text">
                定年退職を迎えたあなたへ！これからの人生を楽しむための名言
              </div>
            </Link>
          </li>

          <li className="recommend__item">
            <Link to="/feature/04/">
              <div className="recommend__img">
                <StaticImage
                  src="../../../assets/images/feature/ochikomu01.jpg"
                  alt="落ち込んでしまったあなたへ。前向きになれる力を持つ名言！"
                />
              </div>
              <div className="recommend__text">
              落ち込んでしまったあなたへ。前向きになれる力を持つ名言！
              </div>
            </Link>
          </li>

          <li className="recommend__item">
            <Link to="/feature/05/">
              <div className="recommend__img">
                <StaticImage
                  src="../../../assets/images/feature/jibunmama01.jpg"
                  alt="自分は自分のままでいい！勇気をもらえる名言！"
                />
              </div>
              <div className="recommend__text">
                自分は自分のままでいい！勇気をもらえる名言！
              </div>
            </Link>
          </li>

          <li className="recommend__item">
            <Link to="/feature/06/">
              <div className="recommend__img">
                <StaticImage
                  src="../../../assets/images/feature/business01.jpg"
                  alt="社会人のあなたへ！乗り越える強さをくれる名言！"
                />
              </div>
              <div className="recommend__text">
              社会人のあなたへ！乗り越える強さをくれる名言！
              </div>
            </Link>
          </li>

          <li className="recommend__item">
            <Link to="/feature/07/">
              <div className="recommend__img">
                <StaticImage
                  src="../../../assets/images/feature/student01.jpg"
                  alt="学生のあなたへ。人生を渡り切る強さをくれる名言！"
                />
              </div>
              <div className="recommend__text">
                学生のあなたへ。人生を渡り切る強さをくれる名言！
              </div>
            </Link>
          </li>

          <li className="recommend__item">
            <Link to="/feature/08/">
              <div className="recommend__img">
                <StaticImage
                  src="../../../assets/images/feature/manager01.jpg"
                  alt="人を導くあなたへ。上司・先生・指導者が知るべき名言！"
                />
              </div>
              <div className="recommend__text">
                人を導くあなたへ。上司・先生・指導者が知るべき名言！
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </>

  )
}

export default FeatureRelated;