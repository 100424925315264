import React from 'react';
import { Link } from "gatsby"

import { GatsbyImage } from 'gatsby-plugin-image'

const FeatureSide = ({ data }) => {
  return (
    <>
      <div className="l-feature__trunk">
        <div className="l-feature__info--tag info--tag">
          <h4 className="m-heading">気分・シチュエーションから探す</h4>
          <ul className="info__person info__person--tag">
            {data.tags.edges.map(( {node }) => {
              return (
                <li className="info__item info__item--tag" key={node.id}>
                  <Link to={`/tags/${node.slug}`}>
                    {node.title}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>

      <div className="l-feature__trunk">
        <div className="l-feature__info info">
          <h4 className="m-heading">偉人から探す</h4>
          <ul className="info__person">
            {data.allCate.edges.map(( {node }) => {
              return (
                <li className="info__item" key={node.id}>
                  <Link to={`/person/${node.slug}`}>
                    <dl className="info__item__details">
                      <dd className="info__item__img">
                        <GatsbyImage
                          className="info__item__photo"
                          image={node.selfie.gatsbyImageData}
                          alt={node.selfie.description}
                        />
                      </dd>
                      <dt className="info__item__ttl">{node.name}</dt>
                    </dl>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>

  )
}

export default FeatureSide;